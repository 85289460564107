//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
// import { i18n } from '@/i18n';

import Roles from '@/security/roles';
import { UserModel } from '@/modules/auth/user-model';
import { FormSchema } from '@/shared/form/form-schema';
const { fields } = UserModel;
const formSchema = new FormSchema([
  fields.emails,
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.avatarsIam,
  fields.rolesRequired,
  fields.accountType,
  fields.countryCode,
]);

export default {
  name: 'app-iam-new-page',

  components: {
    VuePhoneNumberInput,
  },
  async created() {
    await this.doNew();
    this.doReset();
  },
  data() {
    return {
      rules: formSchema.rules(),
      model: {},
      TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'رقم الجوال',
        example: 'مثال :',
      },
      // options: fields.rolesRequired.options,
    };
  },

  computed: {
    ...mapGetters({
      saveLoading: 'iam/form/saveLoading',
      currentLocation: 'layout/currentLocation'
    }),
    fields() {
      return fields;
    },
    countryCode() {
      return this.currentLocation['countryCode'] || 'SA'
    },
    isRTL() {
      return localStorage.getItem('language') == 'ar'
    },
    isUniqueEmail() {
      return (
        !this.model ||
        !this.model.emails ||
        this.model.emails.length <= 1
      );
    },
    rolesRequired() {
      return Roles.selectOptions
    },
  },
  methods: {
    ...mapActions({
      doNew: 'iam/form/doNew',
      doAdd: 'iam/form/doAdd',
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    onUpdate(data) {
      this.model.countryCode = data.countryCode;
    },
    doReset() {
      this.model = formSchema.initialValues();
      this.model.countryCode = this.countryCode;
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      this.model.accountType = 'admin';
      const values = formSchema.cast(this.model);
      return this.doAdd(values);
    },
  },
};
